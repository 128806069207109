export default function Logo() {
    return (
        <svg
            width="87mm"
            height="65mm"
            viewBox="0 0 87 65"
            fill="currentColor"
            className="w-full h-auto"
        >

            <g
                id="layer1"
                transform="translate(-80.06931,-171.85753)"
            >
                <g
                    aria-label="hea roo."
                    transform="matrix(12.814021,0,0,12.699058,-706.37689,-1755.8417)"
                    id="g677"
                >
                    <path
                        d="m 62.805808,152.47381 q 0.372533,0 0.589845,0.25224 0.221192,0.24836 0.221192,0.67522 v 1.26894 h -0.76059 v -1.16805 q 0,-0.18626 -0.09701,-0.29104 -0.09701,-0.10866 -0.259998,-0.10866 -0.170744,0 -0.267758,0.10866 -0.09701,0.10478 -0.09701,0.29104 v 1.16805 h -0.76059 v -2.87161 h 0.76059 v 1.00506 q 0.100894,-0.14746 0.271639,-0.23671 0.174625,-0.0931 0.399698,-0.0931 z"
                        id="path663"
                    />
                    <path
                        d="m 65.826772,153.55649 q 0,0.0892 -0.01164,0.17851 h -1.439688 q 0.01164,0.1785 0.09701,0.26775 0.08925,0.0854 0.225073,0.0854 0.190147,0 0.271639,-0.17075 h 0.811037 q -0.05045,0.22507 -0.197908,0.40358 -0.143581,0.17463 -0.364773,0.27552 -0.221192,0.10089 -0.488951,0.10089 -0.322086,0 -0.574322,-0.13581 -0.248356,-0.13582 -0.391937,-0.38806 -0.1397,-0.25224 -0.1397,-0.59373 0,-0.34149 0.1397,-0.58984 0.1397,-0.25224 0.388056,-0.38806 0.252236,-0.13582 0.578203,-0.13582 0.322087,0 0.570443,0.13194 0.248356,0.13194 0.388056,0.3803 0.1397,0.24447 0.1397,0.5782 z m -0.776112,-0.19015 q 0,-0.1397 -0.09313,-0.21731 -0.09313,-0.0815 -0.232833,-0.0815 -0.1397,0 -0.228953,0.0776 -0.08925,0.0737 -0.116417,0.22119 z"
                        id="path665"
                    />
                    <path
                        d="m 65.834983,153.57977 q 0,-0.3376 0.120297,-0.58984 0.124178,-0.25224 0.333728,-0.38806 0.213431,-0.13582 0.473429,-0.13582 0.225072,0 0.388056,0.0893 0.162983,0.0892 0.252236,0.24059 v -0.30268 h 0.76059 v 2.17699 h -0.76059 v -0.30268 q -0.08925,0.15134 -0.256117,0.24059 -0.162984,0.0892 -0.384175,0.0892 -0.259998,0 -0.473429,-0.13581 -0.20955,-0.13582 -0.333728,-0.38806 -0.120297,-0.25612 -0.120297,-0.59373 z m 1.567746,0 q 0,-0.20955 -0.116417,-0.32984 -0.112536,-0.1203 -0.2794,-0.1203 -0.170745,0 -0.283281,0.1203 -0.112536,0.11641 -0.112536,0.32984 0,0.20955 0.112536,0.33373 0.112536,0.1203 0.283281,0.1203 0.166864,0 0.2794,-0.1203 0.116417,-0.1203 0.116417,-0.33373 z"
                        id="path667"
                    />
                    <path
                        d="m 62.134471,155.09322 q 0.128058,-0.18626 0.310445,-0.29492 0.182386,-0.10866 0.391936,-0.10866 v 0.81104 h -0.213431 q -0.248356,0 -0.368653,0.097 -0.120297,0.097 -0.120297,0.3376 v 0.95074 h -0.76059 v -2.17699 h 0.76059 z"
                        id="path669"
                    />
                    <path
                        d="m 63.956084,156.91321 q -0.325967,0 -0.585965,-0.13582 -0.256117,-0.13582 -0.403578,-0.38806 -0.147461,-0.25224 -0.147461,-0.59373 0,-0.3376 0.147461,-0.58984 0.151342,-0.25224 0.407459,-0.38806 0.259997,-0.13582 0.585965,-0.13582 0.325967,0 0.582084,0.13582 0.259997,0.13582 0.407458,0.38806 0.151342,0.25224 0.151342,0.58984 0,0.33761 -0.151342,0.59373 -0.147461,0.25224 -0.407458,0.38806 -0.259998,0.13582 -0.585965,0.13582 z m 0,-0.6597 q 0.159103,0 0.263878,-0.11642 0.108656,-0.12029 0.108656,-0.34149 0,-0.22119 -0.108656,-0.3376 -0.104775,-0.11642 -0.259997,-0.11642 -0.155223,0 -0.259998,0.11642 -0.104775,0.11641 -0.104775,0.3376 0,0.22508 0.100894,0.34149 0.100895,0.11642 0.259998,0.11642 z"
                        id="path671"
                    />
                    <path
                        d="m 66.258891,156.91321 q -0.325967,0 -0.585965,-0.13582 -0.256117,-0.13582 -0.403578,-0.38806 -0.147461,-0.25224 -0.147461,-0.59373 0,-0.3376 0.147461,-0.58984 0.151342,-0.25224 0.407459,-0.38806 0.259997,-0.13582 0.585964,-0.13582 0.325967,0 0.582084,0.13582 0.259998,0.13582 0.407459,0.38806 0.151342,0.25224 0.151342,0.58984 0,0.33761 -0.151342,0.59373 -0.147461,0.25224 -0.407459,0.38806 -0.259997,0.13582 -0.585964,0.13582 z m 0,-0.6597 q 0.159103,0 0.263878,-0.11642 0.108655,-0.12029 0.108655,-0.34149 0,-0.22119 -0.108655,-0.3376 -0.104775,-0.11642 -0.259998,-0.11642 -0.155222,0 -0.259997,0.11642 -0.104775,0.11641 -0.104775,0.3376 0,0.22508 0.100894,0.34149 0.100895,0.11642 0.259998,0.11642 z"
                        id="path673"
                    />
                    <path
                        d="m 67.777381,156.91709 q -0.175775,0 -0.283945,-0.10866 -0.104789,-0.11254 -0.104789,-0.28328 0,-0.17075 0.104789,-0.28328 0.10817,-0.11254 0.283945,-0.11254 0.172396,0 0.277185,0.11254 0.10817,0.11253 0.10817,0.28328 0,0.16686 -0.10817,0.2794 -0.104789,0.11254 -0.277185,0.11254 z"
                        id="path675"
                    />
                </g>
            </g>
        </svg>

    )
}