import Logo from "./components/Logo";
import hearooAppHomeImage from "./assets/hearoo-app-home.png";
import superheroIcon from "./assets/superheroIcon.svg";
import superheroinIcon from "./assets/superheroinIcon.svg";

import appleStoreBadge from "./assets/apple-store-badge.svg";
import googlePlayBadge from "./assets/google-play-badge.png";
import {useEffect, useState} from "react";

// This app uses tailwind
function App() {
    const currentUri = window.location.href.replace(window.location.origin, '').split('#')[0];
    if (currentUri !== '' && currentUri !== '/') {
        window.location.href = 'https://web.hearoo.app' + currentUri;
    }

    const sectionClasses = "flex flex-col justify-center items-center py-8 my-32 first:my-0 last:my-0";
    const sectionInnerClasses = "container mx-auto px-8";

    const navLinkClasses = "px-2 py-2 uppercase text-sm font-bold lg:px-4 lg:py-3";
    const highlightedNavLinkClasses = navLinkClasses + " bg-white text-primary rounded-md mx-2";

    const defaultCtaClasses = "px-6 py-4 rounded-md font-bold inline-block";
    const ctaClasses = defaultCtaClasses + " bg-white text-primary";
    const darkCtaClasses = defaultCtaClasses + " bg-primary text-white";

    // 45deg gradient
    const gradientBgClasses = "bg-gradient-to-r from-primary to-primary-dark bg-gradient-r-45deg";

    const [statistics, setStatistics] = useState({
        stories: 0,
        users: 0,
        plays: 0,
        shares: 0
    });

    useEffect(() => {
        fetch('https://api.hearoo.app/v1/statistics')
            .then(response => response.json())
            .then(data => setStatistics(data));
    }, []);

    return (
        <>
            <section className={sectionClasses + " " + gradientBgClasses + " text-white"}>
                <header
                    className={sectionInnerClasses + " flex justify-center md:justify-between items-center flex-shrink-0 "}>
                    <a href="/"
                       className="w-20 block ">
                        <Logo/>
                    </a>

                    <nav className="hidden md:block">
                        <ul>
                            <li>
                                <a
                                    className={navLinkClasses}
                                    href="/#about">About</a>
                                <a className={navLinkClasses}
                                   href="/#contact">Contact</a>
                                <a className={highlightedNavLinkClasses}
                                   href="/#download">Download</a>
                            </li>
                        </ul>
                    </nav>
                </header>

                <div className={sectionInnerClasses + " flex items-center justify-between"}>
                    <div className="w-full lg:w-1/2 text-center lg:text-left py-32">
                        <h1 className="text-7xl font-bold hidden lg:block bold mb-8">
                            hearoo.
                        </h1>
                        <h1 className=" text-3xl   mb-8">
                            Because the best story <br
                            className="hidden md:block"/>is your own

                        </h1>
                        <p className="mt-4">
                            <a href="/#about"
                               className={ctaClasses}>Discover</a>
                        </p>
                    </div>

                    <div className="hidden lg:block w-1/2 h-full py-32 pl-16"
                    >
                        <img src={superheroinIcon} alt="Hearoo superhero icon"
                             className="w-1/2 inline-block mx-auto"
                             style={{
                                 transform: "scaleX(-1)"
                             }}
                        />

                        <img src={superheroIcon} alt="Hearoo superhero icon"
                             className="w-1/2 inline-block mx-auto "
                             style={{
                                 transform: "scaleX(-1)",
                                 marginLeft: '-2%'
                             }}
                        />

                    </div>
                </div>
            </section>
            <section id="about" className={sectionClasses}>
                <div className={sectionInnerClasses + " grid grid-cols-1 md:grid-cols-2 gap-8 items-center"}>
                    <a className={"block"}
                       href={"https://web.hearoo.app/stories/new"}>
                        <img src={hearooAppHomeImage} alt="Hero image"
                             className="w-80 h-auto mx-auto rounded-xl"
                        />
                    </a>

                    <div className="text-center md:text-left lg:w-96 mx-auto">

                        <p className=" mb-4 text-gray-400 uppercase">
                            AI audio story generator
                        </p>

                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            Generate your story in 2-clicks
                        </h2>

                        <p className={"mb-4"}>
                            Hearoo is an AI-powered app, crafting personalized audio stories for your unique listening
                            experience. Immerse in creative storytelling catered to your preferences on the go.
                        </p>

                        <a href={"https://web.hearoo.app/stories/new"}
                           className={darkCtaClasses}>Let's gooooo</a>
                    </div>
                </div>
            </section>
            <section id="numbers" className={sectionClasses + " " + gradientBgClasses + " text-white"}>
                <div className={sectionInnerClasses + " text-center py-16"}>
                    <div className={"grid grid-cols-1 md:grid-cols-3 gap-8 items-center"}>
                        <div>
                            <h3 className="text-4xl md:text-5xl font-bold mb-4">
                                {statistics.stories}
                            </h3>
                            <p className={"mb-4 uppercase text-sm"}>
                                Stories generated
                            </p>
                        </div>

                        <div className="text-center">
                            <h3 className="text-4xl md:text-5xl font-bold mb-4">
                                {statistics.users}
                            </h3>
                            <p className={"mb-4 uppercase text-sm"}>
                                Users
                            </p>
                        </div>
                        <div className="text-center">
                            <h3 className="text-4xl md:text-5xl font-bold mb-4">
                                {statistics.plays}
                            </h3>

                            <p className={"mb-4 uppercase text-sm"}>
                                Plays
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <section id="download" className={sectionClasses}>
                <h2 className=" mb-4 text-gray-400 uppercase">
                    Available soon on mobile
                </h2>
                <div className="opacity-50 text-center mb-8"
                     style={{
                         pointerEvents: "none"
                     }}
                >
                    <a href="/"><img
                        className={'w-auto h-16 inline-block m-2'}
                        src={appleStoreBadge} alt={"Download on Apple Store"}/>
                    </a>

                    <a href="/"><img
                        className={'w-auto h-16 inline-block m-2'}
                        src={googlePlayBadge} alt={"Download on Google Play Store"}/>
                    </a>


                </div>

                <p className="text-xl md:text-2xl font-bold mb-4">
                    ...in the meantime, use the web version
                </p>
                <a href="https://web.hearoo.app/stories/new"
                   className={darkCtaClasses}
                >
                    Create a story
                </a>
            </section>
            <section id="contact" className={sectionClasses + " " + gradientBgClasses + " text-white py-24 mb-0"}>
                <div className={sectionInnerClasses + " text-center py-24"}>
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">
                        Contact us
                    </h2>

                    <p className={"mb-16"}>
                        We are always happy to hear from you!
                    </p>

                    <a href="mailto:hello@berenger.dev"
                       className={ctaClasses}>Drop us a line</a>
                </div>
            </section>
            <section id="footer" className={sectionClasses}>
                <div className={sectionInnerClasses + " text-center"}>
                    <p>
                        Made with ❤️ by <a href="https://berenger.dev">Berenger.dev</a>
                    </p>
                </div>
            </section>
        </>
    );
}

export default App;
